<nav class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <div class="navbar-brand"></div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="d-flex navbar-nav me-auto mb-2 mb-lg-0" style="justify-content: space-between; flex-direction: row;">
        <li class="nav-item me-3">
          <span (click)="goHome()" class="nav-link active"><img
              src="../../../assets/icons/icons-navbar/home.svg"></span>
        </li>
        <li class="nav-item me-3">
          <span *ngIf="!tableGame?.startGameLoading && !tableGame?.inResolveGame && playerInTableGame()"
            data-bs-toggle="modal" data-bs-target="#modalBuyCoin" title="Comprar fichas" class="nav-link"><img
              src="../../../assets/icons/icons-navbar/comprar-fichas.svg" (click)="openModalBuyCoin()"></span>
        </li>
        <li class="nav-item me-3">
          <span (click)="goWallet()" class="nav-link"><img src="../../../assets/icons/icons-navbar/carteira.svg"></span>
        </li>
        <li class="nav-item dropdown dropdown-desktop">
          <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span><img src="../../../assets/icons/icons-navbar/baralho.svg"></span>
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item cursor-pointer" (click)="changeDeck('normal')">Normal</a></li>
            <li><a class="dropdown-item cursor-pointer" (click)="changeDeck('colored')">{{ 'GAME_SELECT.COLORED' |
                translate }}</a></li>
          </ul>
        </li>
        <li class="nav-item dropstart dropdown-mobile">
          <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span><img src="../../../assets/icons/icons-navbar/baralho.svg"></span>
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item cursor-pointer" (click)="changeDeck('normal')">Normal</a></li>
            <li><a class="dropdown-item cursor-pointer" (click)="changeDeck('colored')">{{ 'GAME_SELECT.COLORED' |
                translate }}</a></li>
          </ul>
        </li>
      </ul>
      <div class="d-flex align-items-center navbar-nav me-auto mb-2 mb-lg-0">
        <div *ngIf="tableGame?.players" class="text-white table-info ms-2">{{tableGame?.coin}}</div>
        <div *ngIf="tableGame?.bigBlindValue" class="text-white table-info ms-2">{{tableGame!.bigBlindValue /
          2}} / {{tableGame?.bigBlindValue}}</div>
      </div>
      <div class="me-4">
        <a *ngIf="playersInViewOrder[0]?.status == 'playing' && !tableGame?.startGameLoading && !tableGame?.inResolveGame && playerInTableGame()"
          [formGroup]="formCheckBoxs" class="cursor-pointer">
          <input id="absenceCheckbox" (change)="changeAbsence()" class="form-check-input checkbox-yellow cursor-pointer"
            type="checkbox" formControlName="absenceCheckbox" style="width: 12px; height: 12px; margin-top: 15px;">
        </a>
        <a *ngIf="playersInViewOrder[0]?.status == 'playing' && !tableGame?.startGameLoading && !tableGame?.inResolveGame && playerInTableGame()"
          (click)="requestSittingOut()" class="cursor-pointer me-2">
          <img src="../../../assets/game/icons-in-table/cadeira.png" data-bs-toggle="tooltip" title="Ficar ausente"
            alt="" style="height: 24px;">
        </a>
      </div>
      <div class="d-flex justify-content-end">
        <a class="cursor-pointer ms-1" (click)="leavePokerGame()"><img class="icon-logout"
            src="../../../assets/icons/icon-logout.svg" data-bs-toggle="tooltip" title="Sair da mesa" alt=""></a>
      </div>
    </div>
  </div>
</nav>
<!-- <div class="container-fluid" style="position: absolute; top: 50;">
  <div class="container">
    <div class="d-flex flex-fill py-2 px-3 justify-content-between">
      <div class="d-flex">
        <a *ngIf="playerInTableGame() && !tableGame?.startGameLoading && !tableGame?.inResolveGame && canLeavePokerGame" [formGroup]="formCheckBoxs"
          class="cursor-pointer">
          <input id="leaveGameCheckbox" (change)="changeLeaveGame()" class="form-check-input checkbox-red cursor-pointer" type="checkbox" formControlName="leaveGameCheckbox" style="width: 12px; height: 12px; margin-top: 15px;">
        </a>
        <a *ngIf="playerInTableGame() && !tableGame?.startGameLoading && !tableGame?.inResolveGame && canLeavePokerGame" class="cursor-pointer ms-1"
          (click)="leavePokerGame()"><img class="icon-logout" src="../../../assets/icons/icon-logout.svg"
            data-bs-toggle="tooltip" title="Sair da mesa" alt=""></a>
        <div *ngIf="tableGame?.players" class="text-white table-info ms-2">{{tableGame?.coin}}</div>
        <div *ngIf="tableGame?.bigBlindValue" class="text-white table-info ms-2">{{tableGame!.bigBlindValue /
          2}} / {{tableGame?.bigBlindValue}}</div>
      </div>
      <div>
        <a *ngIf="playerInTableGame() && playersInViewOrder[0]?.status == 'playing' && !tableGame?.startGameLoading && !tableGame?.inResolveGame" [formGroup]="formCheckBoxs"
          class="cursor-pointer">
          <input id="absenceCheckbox" (change)="changeAbsence()" class="form-check-input checkbox-yellow cursor-pointer" type="checkbox" formControlName="absenceCheckbox" style="width: 12px; height: 12px; margin-top: 15px;">
        </a>
        <a *ngIf="playerInTableGame() && playersInViewOrder[0]?.status == 'playing' && !tableGame?.startGameLoading && !tableGame?.inResolveGame"
          (click)="requestSittingOut()" class="cursor-pointer me-2">
          <img src="../../../assets/game/icons-in-table/cadeira.png" data-bs-toggle="tooltip" title="Ficar ausente"
            alt="" style="height: 24px;">
        </a>
        <a *ngIf="playerInTableGame() && !tableGame?.startGameLoading && !tableGame?.inResolveGame" class="cursor-pointer" data-bs-toggle="modal"
          data-bs-target="#modalBuyCoin" title="Comprar fichas" (click)="openModalBuyCoin()">
          <img src="../../../assets/game/icons-in-table/fichas-de-cassino.png" alt="">
        </a>
      </div>
    </div>
  </div>
</div> -->

<section class="bg-table d-flex flex-column align-items-center">
  <div class="container-scroll">
    <div class="d-flex flex-column justify-content-center">
      <div class="mesa mt-5">
        <div class="d-flex justify-content-center teste">

          <p>
            <span *ngIf="tableGame?.bigBlindValue" class="text-white" (click)="animacao()">{{tableGame?.coin}} &nbsp;
              {{tableGame!.bigBlindValue /2}} / {{tableGame?.bigBlindValue}}</span>
            <br>
            <span *ngIf="tableGame?.text" class="text-white">{{tableGame?.text}}</span>
          </p>
        </div>

        <!-- Jogador 0 -->
        <div *ngIf="playersInViewOrder[0]"
          class="player0 d-flex flex-fill flex-column align-items-center justify-content-center">

          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="playersInViewOrder[0].status == 'empty' ? 'visibility-hidden' : ''">

            <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
              [ngClass]="[playersInViewOrder[0].status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[0].status !== 'playing' ? 'opacity-05' : '']">

              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[0].user?._id ? 'active-player-border' : ''"
                class="profilePicture0 d-flex align-items-center justify-content-center"
                [style.border]="getPlayerBorderColor(playersInViewOrder[0])" (click)="selectPlayerColor(0)">

                <div *ngIf="playersInViewOrder[0]?.playerData && !playersInViewOrder[0]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{playersInViewOrder[0].playerData!.nickname[0]}}
                </div>
                <img *ngIf="playersInViewOrder[0]?.playerData && playersInViewOrder[0]?.playerData?.profilePicture"
                  class="img-fluid img0" src="{{playersInViewOrder[0].playerData!.profilePicture}}">
              </div>
              <div *ngIf="playersInViewOrder[0].status == 'absence'"
                class="sittingOut0 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[0].user?._id ? 'active-player-border' : ''"
                class="info0">
                <div *ngIf="!showActionPlayer0" class="nickname0 d-flex w-100 justify-content-center text-nowrap">
                  {{playersInViewOrder[0].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 0 && showActionPlayer0"
                  class="nickname0 d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                <div (click)="changeShowValuesInBigBlindsFormat()"
                  *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[0].coinAmount > 0 || playersInViewOrder[0].coinAmount == 0 && playersInViewOrder[0].status !== 'playing')"
                  class="balance0 cursor-pointer d-flex w-100 justify-content-center">{{playersInViewOrder[0].coinAmount
                  / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div (click)="changeShowValuesInBigBlindsFormat()"
                  *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[0].coinAmount > 0 || playersInViewOrder[0].coinAmount == 0 && playersInViewOrder[0].status !== 'playing')"
                  class="balance0 cursor-pointer d-flex w-100 justify-content-center">
                  {{playersInViewOrder[0].coinAmount}}</div>
                <div (click)="changeShowValuesInBigBlindsFormat()"
                  *ngIf="playersInViewOrder[0].coinAmount == 0 && playersInViewOrder[0].status == 'playing'"
                  class="balance0 cursor-pointer d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="tableGame?.currentTurn == playersInViewOrder[0].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                class="progress-bar0">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }"></div>
                <div
                  *ngIf="tableGame?.currentTurn == playersInViewOrder[0].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                  class="time-counter align-items-center">
                  TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!) }}
                </div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[0] && playersInViewOrder[0]?.playerHand && playersInViewOrder[0]?.playerHand?.cards!.length && !isFold(0) && !playerInTableGame()"
                class="div-cards-face-down0">
                <img class="card-face-down0-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(0)">
                <img class="card-face-down0-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(0)">
              </div>
              <div
                *ngIf="!arrayShowdownIndex[0] && playersInViewOrder[0]?.playerHand && playersInViewOrder[0]?.playerHand?.cards!.length && isMainPlayer() && !isFold(0)"
                class="div-card-player0">
                <img class="card-player0-1"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[0].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(0)">
                <img class="card-player0-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[0].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(0)">
              </div>
              <div *ngIf="arrayShowdownIndex[0] && playersInViewOrder[0]?.playerHand?.cards?.length && !isFold(0)"
                class="div-showdown-player0">
                <img class="showdown-card-player0-1" Buy
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[0].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(0)">
                <img class="showdown-card-player0-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[0].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(0)">
              </div>
            </div>
            <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[0]?.user?._id" class="dealer0"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div class="bet0">
              <div *ngIf="betByPlayer(0) > 0 && !tableGame?.inResolveGame">
                <ng-container *ngFor="let chip of getChipColor(betByPlayer(0)) | keyvalue">
                  <div class="chip-stack">
                    <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                  </div>
                </ng-container>
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(0) / tableGame?.bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(0)}}</span>
              </div>
            </div>
          </div>
          <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(0)"
            [ngClass]="playersInViewOrder[0] && playersInViewOrder[0].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player0 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(0)"
            [ngClass]="playersInViewOrder[0] && playersInViewOrder[0].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player0 cursor-pointer" src="../../../assets/icons/sentar.svg" alt="">
        </div>

        <!-- ALL PLAYERS -->
        <div *ngFor="let player of players; let i = index">

          <div *ngIf="playersInViewOrder[i + 1]"
            class="player{{i + 1}} d-flex flex-fill flex-column align-items-center justify-content-center">

            <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
              [ngClass]="playersInViewOrder[i + 1]?.status == 'empty' ? 'visibility-hidden' : ''">

              <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
                [ngClass]="[playersInViewOrder[i + 1]?.status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[i + 1]?.status !== 'playing' ? 'opacity-05' : '']">

                  <div
                    [ngClass]="playersInViewOrder[i + 1] && tableGame?.currentTurn == playersInViewOrder[i + 1]?.user?._id ? 'active-player-border' : ''"
                    class="profilePicture{{i + 1}} d-flex align-items-center justify-content-center"
                    [style.border]="getPlayerBorderColor(playersInViewOrder[i + 1])" (click)="selectPlayerColor(i + 1)">

                    <div
                      *ngIf="playersInViewOrder[i + 1]?.playerData && !playersInViewOrder[i + 1]?.playerData?.profilePicture"
                      class="text-uppercase">
                      {{playersInViewOrder[i + 1].playerData!.nickname[0]}}
                    </div>
                    <img
                      *ngIf="playersInViewOrder[i + 1]?.playerData && playersInViewOrder[i + 1]?.playerData?.profilePicture"
                      class="img-fluid img{{i + 1}}" [src]="playersInViewOrder[i + 1].playerData!.profilePicture">
                  </div>

                  <div *ngIf="playersInViewOrder[i + 1]?.status == 'absence'"
                    class="sittingOut{{i + 1}} d-flex w-100 justify-content-center">Sitting Out</div>
                  <div
                    [ngClass]="tableGame?.currentTurn == playersInViewOrder[i + 1]?.user?._id ? 'active-player-border' : ''"
                    class="info{{i + 1}}">
                    <div *ngIf="!showActionPlayerIndex(i + 1)"
                      class="nickname{{i + 1}} d-flex w-100 justify-content-center text-nowrap">
                      {{playersInViewOrder[i + 1]?.playerData?.nickname}}
                    </div>
                    <div *ngIf="animationPlayerIndex === (i + 1) && showActionPlayerIndex(i + 1)"
                      class="nickname{{i + 1}} d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                    <div
                      *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[i + 1]?.coinAmount! > 0 || (playersInViewOrder[i + 1]?.coinAmount == 0 && playersInViewOrder[i + 1]?.status !== 'playing'))"
                      class="balance{{i + 1}} d-flex w-100 justify-content-center">
                      {{playersInViewOrder[i + 1]?.coinAmount! / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB
                    </div>
                    <div
                      *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[i + 1]?.coinAmount! > 0 || (playersInViewOrder[i + 1]?.coinAmount == 0 && playersInViewOrder[i + 1]?.status !== 'playing'))"
                      class="balance{{i + 1}} d-flex w-100 justify-content-center">
                      {{playersInViewOrder[i + 1]?.coinAmount!}}
                    </div>
                    <div
                      *ngIf="playersInViewOrder[i + 1]?.coinAmount == 0 && playersInViewOrder[i + 1]?.status == 'playing'"
                      class="balance{{i + 1}} d-flex w-100 justify-content-center">All In
                    </div>
                  </div>
                  <div
                    [ngClass]="tableGame?.currentTurn == playersInViewOrder[i + 1]?.user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                    class="progress-bar{{i + 1}}">
                    <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                      [ngClass]="{'bg-red': progressWidth <= 25 }">
                      <div
                        *ngIf="tableGame?.currentTurn == playersInViewOrder[i + 1]?.user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                        class="time-counter mt-1">
                        TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[i + 1]?.user?._id!) }}
                      </div>
                    </div>
                  </div>
                  <div
                    *ngIf="!arrayShowdownIndex[i + 1] && playersInViewOrder[i + 1]?.playerHand && playersInViewOrder[i + 1]?.playerHand?.cards!.length && !isFold(i + 1)"
                    class="div-cards-face-down{{i + 1}}">
                    <img class="card-face-down1-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                      (click)="selectPlayerColor(i + 1)">
                    <img class="card-face-down1-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                      (click)="selectPlayerColor(i + 1)">
                  </div>

                <div
                  *ngIf="arrayShowdownIndex[i + 1] && playersInViewOrder[i + 1]?.playerHand?.cards?.length && !isFold(i + 1)"
                  class="div-showdown-player{{i + 1}}">
                  <img class="showdown-card-player{{i + 1}}-1"
                    [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[i + 1]?.playerHand!.cards[0] + '.png'"
                    (click)="selectPlayerColor(i + 1)">
                  <img class="showdown-card-player{{i + 1}}-2"
                    [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[i + 1]?.playerHand!.cards[1] + '.png'"
                    (click)="selectPlayerColor(i + 1)">
                </div>

              </div>
              <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[i + 1]?.user?._id" class="dealer{{i + 1}}"
                src="../../../assets/icons/poker-game/coin-dealer.svg">
              <div *ngIf="betByPlayer(i + 1) > 0 && !tableGame?.inResolveGame" class="bet{{i + 1}}">
                <div>
                  <ng-container *ngFor="let chip of getChipColor(betByPlayer(i + 1)) | keyvalue">
                    <div class="chip-stack">
                      <img *ngFor="let _ of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                    </div>
                  </ng-container>
                  <span *ngIf="showValuesInBigBlindsFormat" class="me-2">
                    {{betByPlayer(i + 1) / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB
                  </span>
                  <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">
                    $ {{betByPlayer(i + 1)}}
                  </span>
                </div>
              </div>
            </div>
            <img data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(i + 1)"
              [ngClass]="playersInViewOrder[i + 1] && playersInViewOrder[i + 1]?.status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
              class="add-player{{i + 1}} cursor-pointer"
              [src]="lang === 'en' ? '../../../assets/icons/take-seat.svg' : '../../../assets/icons/sentar.svg'" alt="">
          </div>
        </div>

        <!-- Jogador 1 -->
        <!-- <div *ngIf="playersInViewOrder[1]"
          class="player1 d-flex flex-fill flex-column align-items-center justify-content-center">



          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="playersInViewOrder[1].status == 'empty' ? 'visibility-hidden' : ''">



            <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
              [ngClass]="[playersInViewOrder[1].status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[1].status !== 'playing' ? 'opacity-05' : '']">



              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[1].user?._id ? 'active-player-border' : ''"
                class="profilePicture1 d-flex align-items-center justify-content-center" [style.border]="getPlayerBorderColor(playersInViewOrder[1])" (click)="selectPlayerColor(1)">




                <div *ngIf="playersInViewOrder[1]?.playerData && !playersInViewOrder[1]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{playersInViewOrder[1].playerData!.nickname[0]}}
                </div>
                <img *ngIf="playersInViewOrder[1]?.playerData && playersInViewOrder[1]?.playerData?.profilePicture"
                  class="img-fluid img1" src="{{playersInViewOrder[1].playerData!.profilePicture}}">



              </div>


              <div *ngIf="playersInViewOrder[1].status == 'absence'"
                class="sittingOut1 d-flex w-100 justify-content-center">Sitting Out</div>


              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[1].user?._id ? 'active-player-border' : ''" class="info1">



                <div *ngIf="!showActionPlayer1" class="nickname1 d-flex w-100 justify-content-center text-nowrap">
                  {{playersInViewOrder[1].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 1 && showActionPlayer1"
                  class="nickname1 d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[1].coinAmount > 0 || playersInViewOrder[1].coinAmount == 0 && playersInViewOrder[1].status !== 'playing')"
                  class="balance1 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[1].coinAmount / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[1].coinAmount > 0 || playersInViewOrder[1].coinAmount == 0 && playersInViewOrder[1].status !== 'playing')"
                  class="balance1 d-flex w-100 justify-content-center"> {{playersInViewOrder[1].coinAmount}}</div>
                <div *ngIf="playersInViewOrder[1].coinAmount == 0 && playersInViewOrder[1].status == 'playing'"
                  class="balance1 d-flex w-100 justify-content-center">All In</div>



              </div>
              <div
                [ngClass]="tableGame?.currentTurn == playersInViewOrder[1].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                class="progress-bar1">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }">
                  <div
                    *ngIf="tableGame?.currentTurn == playersInViewOrder[1].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                    class="time-counter mt-1">
                    TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[1].user?._id!) }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[1] && playersInViewOrder[1]?.playerHand && playersInViewOrder[1]?.playerHand?.cards!.length && !isFold(1)"
                class="div-cards-face-down1">
                <img class="card-face-down1-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(1)">
                <img class="card-face-down1-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(1)">
              </div>
              <div *ngIf="arrayShowdownIndex[1] && playersInViewOrder[1]?.playerHand?.cards?.length && !isFold(1)"
                class="div-showdown-player1">
                <img class="showdown-card-player1-1"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[1].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(1)">
                <img class="showdown-card-player1-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[1].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(1)">
              </div>
            </div>
            <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[1]?.user?._id" class="dealer1"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(1) > 0 && !tableGame?.inResolveGame" class="bet1">
              <div>
                <ng-container *ngFor="let chip of getChipColor(betByPlayer(1)) | keyvalue">
                  <div class="chip-stack">
                    <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                  </div>
                </ng-container>
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(1) / tableGame?.bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(1)}}</span>
              </div>
            </div>
          </div>
          <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(1)"
            [ngClass]="playersInViewOrder[1] && playersInViewOrder[1].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player1 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(1)"
            [ngClass]="playersInViewOrder[1] && playersInViewOrder[1].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player1 cursor-pointer" src="../../../assets/icons/sentar.svg" alt="">
        </div> -->


        <!-- Jogador 2 -->
        <!-- <div *ngIf="playersInViewOrder[2]"
          class="player2 d-flex flex-fill flex-column align-items-center justify-content-center">



          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="playersInViewOrder[2].status == 'empty' ? 'visibility-hidden' : ''">

            <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
              [ngClass]="[playersInViewOrder[2].status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[2].status !== 'playing' ? 'opacity-05' : '']">


              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[2].user?._id ? 'active-player-border' : ''"
                class="profilePicture2 d-flex align-items-center justify-content-center"
                [style.border]="getPlayerBorderColor(playersInViewOrder[2])" (click)="selectPlayerColor(2)">



                <div *ngIf="playersInViewOrder[2]?.playerData && !playersInViewOrder[2]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{playersInViewOrder[2].playerData!.nickname[0]}}
                </div>
                <img *ngIf="playersInViewOrder[2]?.playerData && playersInViewOrder[2]?.playerData?.profilePicture"
                  class="img-fluid img2" src="{{playersInViewOrder[2].playerData!.profilePicture}}">
              </div>
              <div *ngIf="playersInViewOrder[2].status == 'absence'"
                class="sittingOut2 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[2].user?._id ? 'active-player-border' : ''"
                class="info2">
                <div *ngIf="!showActionPlayer2" class="nickname2 d-flex w-100 justify-content-center text-nowrap">
                  {{playersInViewOrder[2].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 2 && showActionPlayer2"
                  class="nickname2 d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[2].coinAmount > 0 || playersInViewOrder[2].coinAmount == 0 && playersInViewOrder[2].status !== 'playing')"
                  class="balance2 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[2].coinAmount / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[2].coinAmount > 0 || playersInViewOrder[2].coinAmount == 0 && playersInViewOrder[2].status !== 'playing')"
                  class="balance2 d-flex w-100 justify-content-center">{{playersInViewOrder[2].coinAmount}}</div>
                <div *ngIf="playersInViewOrder[2].coinAmount == 0 && playersInViewOrder[2].status == 'playing'"
                  class="balance2 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="tableGame?.currentTurn == playersInViewOrder[2].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                class="progress-bar2">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }">
                  <div
                    *ngIf="tableGame?.currentTurn == playersInViewOrder[2].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                    class="time-counter mt-1">
                    TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[2].user?._id!) }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[2] && playersInViewOrder[2]?.playerHand && playersInViewOrder[2]?.playerHand?.cards!.length && !isFold(2)"
                class="div-cards-face-down2" (click)="selectPlayerColor(2)">
                <img class="card-face-down2-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(2)">
                <img class="card-face-down2-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(2)">
              </div>
              <div *ngIf="arrayShowdownIndex[2] && playersInViewOrder[2]?.playerHand?.cards?.length && !isFold(2)"
                class="div-showdown-player2" (click)="selectPlayerColor(2)">
                <img class="showdown-card-player2-1"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[2].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(2)">
                <img class="showdown-card-player2-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[2].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(2)">
              </div>
            </div>
            <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[2]?.user?._id" class="dealer2"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(2) > 0 && !tableGame?.inResolveGame" class="bet2">
              <div>
                <ng-container *ngFor="let chip of getChipColor(betByPlayer(2)) | keyvalue">
                  <div class="chip-stack">
                    <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                  </div>
                </ng-container>
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(2) / tableGame?.bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(2)}}</span>
              </div>
            </div>
          </div>
          <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(2)"
            [ngClass]="playersInViewOrder[2] && playersInViewOrder[2].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player2 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(2)"
            [ngClass]="playersInViewOrder[2] && playersInViewOrder[2].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player2 cursor-pointer" src="../../../assets/icons/sentar.svg" alt="">
        </div> -->


        <!-- Jogador 3 -->
        <!-- <div *ngIf="playersInViewOrder[3]"
          class="player3 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="playersInViewOrder[3].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
              [ngClass]="[playersInViewOrder[3].status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[3].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[3].user?._id ? 'active-player-border' : ''"
                class="profilePicture3 d-flex align-items-center justify-content-center"
                [style.border]="getPlayerBorderColor(playersInViewOrder[3])" (click)="selectPlayerColor(3)">
                <div *ngIf="playersInViewOrder[3]?.playerData && !playersInViewOrder[3]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{playersInViewOrder[3].playerData!.nickname[0]}}
                </div>
                <img *ngIf="playersInViewOrder[3]?.playerData && playersInViewOrder[3]?.playerData?.profilePicture"
                  class="img-fluid img3" src="{{playersInViewOrder[3].playerData!.profilePicture}}">
              </div>
              <div *ngIf="playersInViewOrder[3].status == 'absence'"
                class="sittingOut3 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[3].user?._id ? 'active-player-border' : ''"
                class="info3">
                <div *ngIf="!showActionPlayer3" class="nickname3 d-flex w-100 justify-content-center text-nowrap">
                  {{playersInViewOrder[3].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 3 && showActionPlayer3"
                  class="nickname3 d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[3].coinAmount > 0 || playersInViewOrder[3].coinAmount == 0 && playersInViewOrder[3].status !== 'playing')"
                  class="balance3 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[3].coinAmount / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[3].coinAmount > 0 || playersInViewOrder[3].coinAmount == 0 && playersInViewOrder[3].status !== 'playing')"
                  class="balance3 d-flex w-100 justify-content-center"> {{playersInViewOrder[3].coinAmount}}</div>
                <div *ngIf="playersInViewOrder[3].coinAmount == 0 && playersInViewOrder[3].status == 'playing'"
                  class="balance3 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="tableGame?.currentTurn == playersInViewOrder[3].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                class="progress-bar3">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }">
                  <div
                    *ngIf="tableGame?.currentTurn == playersInViewOrder[3].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                    class="time-counter mt-1">
                    TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[3].user?._id!) }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[3] && playersInViewOrder[3]?.playerHand && playersInViewOrder[3]?.playerHand?.cards!.length && !isFold(3)"
                class="div-cards-face-down3" (click)="selectPlayerColor(3)">
                <img class="card-face-down3-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(3)">
                <img class="card-face-down3-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(3)">
              </div>
              <div *ngIf="arrayShowdownIndex[3] && playersInViewOrder[3]?.playerHand?.cards?.length && !isFold(3)"
                class="div-showdown-player3" (click)="selectPlayerColor(3)">
                <img class="showdown-card-player3-1"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[3].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(3)">
                <img class="showdown-card-player3-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[3].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(3)">
              </div>
            </div>
            <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[3]?.user?._id" class="dealer3"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(3) > 0 && !tableGame?.inResolveGame" class="bet3">
              <div>
                <ng-container *ngFor="let chip of getChipColor(betByPlayer(3)) | keyvalue">
                  <div class="chip-stack">
                    <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                  </div>
                </ng-container>
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(3) / tableGame?.bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(3)}}</span>
              </div>
            </div>
          </div>
          <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(3)"
            [ngClass]="playersInViewOrder[3] && playersInViewOrder[3].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player3 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(3)"
            [ngClass]="playersInViewOrder[3] && playersInViewOrder[3].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player3 cursor-pointer" src="../../../assets/icons/sentar.svg" alt="">
        </div> -->


        <!-- Jogador 4 -->
        <!-- <div *ngIf="playersInViewOrder[4]"
          class="player4 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="playersInViewOrder[4].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
              [ngClass]="[playersInViewOrder[4].status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[4].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[4].user?._id ? 'active-player-border' : ''"
                class="profilePicture4 d-flex align-items-center justify-content-center"
                [style.border]="getPlayerBorderColor(playersInViewOrder[4])" (click)="selectPlayerColor(4)">
                <div *ngIf="playersInViewOrder[4]?.playerData && !playersInViewOrder[4]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{playersInViewOrder[4].playerData!.nickname[0]}}
                </div>
                <img *ngIf="playersInViewOrder[4]?.playerData && playersInViewOrder[4]?.playerData?.profilePicture"
                  class="img-fluid img4" src="{{playersInViewOrder[4].playerData!.profilePicture}}">
              </div>
              <div *ngIf="playersInViewOrder[4].status == 'absence'"
                class="sittingOut4 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[4].user?._id ? 'active-player-border' : ''"
                class="info4">
                <div *ngIf="!showActionPlayer4" class="nickname4 d-flex w-100 justify-content-center text-nowrap">
                  {{playersInViewOrder[4].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 4 && showActionPlayer4"
                  class="nickname4 d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[4].coinAmount > 0 || playersInViewOrder[4].coinAmount == 0 && playersInViewOrder[4].status !== 'playing')"
                  class="balance4 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[4].coinAmount / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[4].coinAmount > 0 || playersInViewOrder[4].coinAmount == 0 && playersInViewOrder[4].status !== 'playing')"
                  class="balance4 d-flex w-100 justify-content-center">{{playersInViewOrder[4].coinAmount}}</div>
                <div *ngIf="playersInViewOrder[4].coinAmount == 0 && playersInViewOrder[4].status == 'playing'"
                  class="balance4 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="tableGame?.currentTurn == playersInViewOrder[4].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                class="progress-bar4">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }">
                  <div
                    *ngIf="tableGame?.currentTurn == playersInViewOrder[4].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                    class="time-counter mt-1">
                    TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[4].user?._id!) }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[4] && playersInViewOrder[4]?.playerHand && playersInViewOrder[4]?.playerHand?.cards!.length && !isFold(4)"
                class="div-cards-face-down4" (click)="selectPlayerColor(4)">
                <img class="card-face-down4-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(4)">
                <img class="card-face-down4-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(4)">
              </div>
              <div *ngIf="arrayShowdownIndex[4] && playersInViewOrder[4]?.playerHand?.cards?.length && !isFold(4)"
                class="div-showdown-player4" (click)="selectPlayerColor(4)">
                <img class="showdown-card-player4-1"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[4].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(4)">
                <img class="showdown-card-player4-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[4].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(4)">
              </div>
            </div>
            <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[4]?.user?._id" class="dealer4"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(4) > 0 && !tableGame?.inResolveGame" class="bet4">
              <div>
                <ng-container *ngFor="let chip of getChipColor(betByPlayer(4)) | keyvalue">
                  <div class="chip-stack">
                    <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                  </div>
                </ng-container>
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(4) / tableGame?.bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="ms-2">$ {{betByPlayer(4)}}</span>
              </div>
            </div>
          </div>
          <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(4)"
            [ngClass]="playersInViewOrder[4] && playersInViewOrder[4].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player4 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(4)"
            [ngClass]="playersInViewOrder[4] && playersInViewOrder[4].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player4 cursor-pointer" src="../../../assets/icons/sentar.svg" alt="">
        </div> -->


        <!-- Jogador 5 -->
        <!-- <div *ngIf="playersInViewOrder[5]"
          class="player5 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="playersInViewOrder[5].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
              [ngClass]="[playersInViewOrder[5].status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[5].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[5].user?._id ? 'active-player-border' : ''"
                class="profilePicture5 d-flex align-items-center justify-content-center"
                [style.border]="getPlayerBorderColor(playersInViewOrder[5])" (click)="selectPlayerColor(5)">
                <div *ngIf="playersInViewOrder[5]?.playerData && !playersInViewOrder[5]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{playersInViewOrder[5].playerData!.nickname[0]}}
                </div>
                <img *ngIf="playersInViewOrder[5]?.playerData && playersInViewOrder[5]?.playerData?.profilePicture"
                  class="img-fluid img5" src="{{playersInViewOrder[5].playerData!.profilePicture}}">
              </div>
              <div *ngIf="playersInViewOrder[5].status == 'absence'"
                class="sittingOut5 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[5].user?._id ? 'active-player-border' : ''"
                class="info5">
                <div *ngIf="!showActionPlayer5" class="nickname5 d-flex w-100 justify-content-center text-nowrap">
                  {{playersInViewOrder[5].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 5 && showActionPlayer5"
                  class="nickname5 d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[5].coinAmount > 0 || playersInViewOrder[5].coinAmount == 0 && playersInViewOrder[5].status !== 'playing')"
                  class="balance5 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[5].coinAmount / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[5].coinAmount > 0 || playersInViewOrder[5].coinAmount == 0 && playersInViewOrder[5].status !== 'playing')"
                  class="balance5 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[5].coinAmount}}</div>
                <div *ngIf="playersInViewOrder[5].coinAmount == 0 && playersInViewOrder[5].status == 'playing'"
                  class="balance5 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="tableGame?.currentTurn == playersInViewOrder[5].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                class="progress-bar5">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }">
                  <div
                    *ngIf="tableGame?.currentTurn == playersInViewOrder[5].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                    class="time-counter mt-1">
                    TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[5].user?._id!) }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[5] && playersInViewOrder[5]?.playerHand && playersInViewOrder[5]?.playerHand?.cards!.length && !isFold(5)"
                class="div-cards-face-down5" (click)="selectPlayerColor(5)">
                <img class="card-face-down5-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(5)">
                <img class="card-face-down5-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(5)">
              </div>
              <div *ngIf="arrayShowdownIndex[5] && playersInViewOrder[5]?.playerHand?.cards?.length && !isFold(5)"
                class="div-showdown-player5" (click)="selectPlayerColor(5)">
                <img class="showdown-card-player5-1"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[5].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(5)">
                <img class="showdown-card-player5-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[5].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(5)">
              </div>
            </div>
            <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[5]?.user?._id" class="dealer5"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(5) > 0 && !tableGame?.inResolveGame" class="bet5">
              <div class="d-flex flex-row-reverse">
                <ng-container *ngFor="let chip of getChipColor(betByPlayer(5)) | keyvalue">
                  <div class="chip-stack">
                    <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                  </div>
                </ng-container>
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(5) / tableGame?.bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="me-2">$ {{betByPlayer(5)}}</span>
              </div>
            </div>
          </div>
          <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(5)"
            [ngClass]="playersInViewOrder[5] && playersInViewOrder[5].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player5 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(5)"
            [ngClass]="playersInViewOrder[5] && playersInViewOrder[5].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player5 cursor-pointer" src="../../../assets/icons/sentar.svg" alt="">
        </div> -->

        <!-- Jogador 6 -->
        <!-- <div *ngIf="playersInViewOrder[6]"
          class="player6 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="playersInViewOrder[6].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
              [ngClass]="[playersInViewOrder[6].status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[6].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[6].user?._id ? 'active-player-border' : ''"
                class="profilePicture6 d-flex align-items-center justify-content-center"
                [style.border]="getPlayerBorderColor(playersInViewOrder[6])" (click)="selectPlayerColor(6)">
                <div *ngIf="playersInViewOrder[6]?.playerData && !playersInViewOrder[6]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{playersInViewOrder[6].playerData!.nickname[0]}}
                </div>
                <img *ngIf="playersInViewOrder[6]?.playerData && playersInViewOrder[6]?.playerData?.profilePicture"
                  class="img-fluid img6" src="{{playersInViewOrder[6].playerData!.profilePicture}}">
              </div>
              <div *ngIf="playersInViewOrder[6].status == 'absence'"
                class="sittingOut6 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[6].user?._id ? 'active-player-border' : ''"
                class="info6">
                <div *ngIf="!showActionPlayer6" class="nickname6 d-flex w-100 justify-content-center text-nowrap">
                  {{playersInViewOrder[6].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 6 && showActionPlayer6"
                  class="nickname6 d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[6].coinAmount > 0 || playersInViewOrder[6].coinAmount == 0 && playersInViewOrder[6].status !== 'playing')"
                  class="balance6 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[6].coinAmount / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[6].coinAmount > 0 || playersInViewOrder[6].coinAmount == 0 && playersInViewOrder[6].status !== 'playing')"
                  class="balance6 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[6].coinAmount}}</div>
                <div *ngIf="playersInViewOrder[6].coinAmount == 0 && playersInViewOrder[6].status == 'playing'"
                  class="balance6 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="tableGame?.currentTurn == playersInViewOrder[6].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                class="progress-bar6">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }">
                  <div
                    *ngIf="tableGame?.currentTurn == playersInViewOrder[6].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                    class="time-counter mt-1">
                    TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[6].user?._id!) }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[6] && playersInViewOrder[6]?.playerHand && playersInViewOrder[6]?.playerHand?.cards!.length && !isFold(6)"
                class="div-cards-face-down6" (click)="selectPlayerColor(6)">
                <img class="card-face-down6-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(6)">
                <img class="card-face-down6-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(6)">
              </div>
              <div *ngIf="arrayShowdownIndex[6] && playersInViewOrder[6]?.playerHand?.cards?.length && !isFold(6)"
                class="div-showdown-player6" (click)="selectPlayerColor(6)">
                <img class="showdown-card-player6-1"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[6].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(6)">
                <img class="showdown-card-player6-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[6].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(6)">
              </div>
            </div>
            <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[6]?.user?._id" class="dealer6"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(6) > 0 && !tableGame?.inResolveGame" class="bet6">
              <div class="d-flex flex-row-reverse">
                <ng-container *ngFor="let chip of getChipColor(betByPlayer(6)) | keyvalue">
                  <div class="chip-stack">
                    <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                  </div>
                </ng-container>
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(6) / tableGame?.bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="me-2">$ {{betByPlayer(6)}}</span>
              </div>
            </div>
          </div>
          <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(6)"
            [ngClass]="playersInViewOrder[6] && playersInViewOrder[6].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player6 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(6)"
            [ngClass]="playersInViewOrder[6] && playersInViewOrder[6].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player6 cursor-pointer" src="../../../assets/icons/sentar.svg" alt="">
        </div> -->


        <!-- Jogador 7 -->
        <!-- <div *ngIf="playersInViewOrder[7]"
          class="player7 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="playersInViewOrder[7].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
              [ngClass]="[playersInViewOrder[7].status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[7].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[7].user?._id ? 'active-player-border' : ''"
                class="profilePicture7 d-flex align-items-center justify-content-center"
                [style.border]="getPlayerBorderColor(playersInViewOrder[7])" (click)="selectPlayerColor(7)">
                <div *ngIf="playersInViewOrder[7]?.playerData && !playersInViewOrder[7]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{playersInViewOrder[7].playerData!.nickname[0]}}
                </div>
                <img *ngIf="playersInViewOrder[7]?.playerData && playersInViewOrder[7]?.playerData?.profilePicture"
                  class="img-fluid img7" src="{{playersInViewOrder[7].playerData!.profilePicture}}">
              </div>
              <div *ngIf="playersInViewOrder[7].status == 'absence'"
                class="sittingOut7 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[7].user?._id ? 'active-player-border' : ''"
                class="info7">
                <div *ngIf="!showActionPlayer7" class="nickname7 d-flex w-100 justify-content-center text-nowrap">
                  {{playersInViewOrder[7].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 7 && showActionPlayer7"
                  class="nickname7 d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[7].coinAmount > 0 || playersInViewOrder[7].coinAmount == 0 && playersInViewOrder[7].status !== 'playing')"
                  class="balance7 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[7].coinAmount / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[7].coinAmount > 0 || playersInViewOrder[7].coinAmount == 0 && playersInViewOrder[7].status !== 'playing')"
                  class="balance7 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[7].coinAmount}}</div>
                <div *ngIf="playersInViewOrder[7].coinAmount == 0 && playersInViewOrder[7].status == 'playing'"
                  class="balance7 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="tableGame?.currentTurn == playersInViewOrder[7].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                class="progress-bar7">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }">
                  <div
                    *ngIf="tableGame?.currentTurn == playersInViewOrder[7].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                    class="time-counter mt-1">
                    TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[7].user?._id!) }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[7] && playersInViewOrder[7]?.playerHand && playersInViewOrder[7]?.playerHand?.cards!.length && !isFold(7)"
                class="div-cards-face-down7" (click)="selectPlayerColor(7)">
                <img class="card-face-down7-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(7)">
                <img class="card-face-down7-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(7)">
              </div>
              <div *ngIf="arrayShowdownIndex[7] && playersInViewOrder[7]?.playerHand?.cards?.length && !isFold(7)"
                class="div-showdown-player7" (click)="selectPlayerColor(7)">
                <img class="showdown-card-player7-1"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[7].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(7)">
                <img class="showdown-card-player7-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[7].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(7)">
              </div>
            </div>
            <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[7]?.user?._id" class="dealer7"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(7) > 0 && !tableGame?.inResolveGame" class="bet7">
              <div class="d-flex flex-row-reverse">
                <ng-container *ngFor="let chip of getChipColor(betByPlayer(7)) | keyvalue">
                  <div class="chip-stack">
                    <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                  </div>
                </ng-container>
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(7) / tableGame?.bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="me-2">$ {{betByPlayer(7)}}</span>
              </div>
            </div>
          </div>
          <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(7)"
            [ngClass]="playersInViewOrder[7] && playersInViewOrder[7].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player7 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(7)"
            [ngClass]="playersInViewOrder[7] && playersInViewOrder[7].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player7 cursor-pointer" src="../../../assets/icons/sentar.svg" alt="">
        </div> -->


        <!-- Jogador 8 -->
        <!-- <div *ngIf="playersInViewOrder[8]"
          class="player8 d-flex flex-fill flex-column align-items-center justify-content-center">
          <div class="d-flex flex-fill flex-column align-items-center justify-content-center"
            [ngClass]="playersInViewOrder[8].status == 'empty' ? 'visibility-hidden' : ''">
            <div class="d-flex flex-fill flex-column align-items-center justify-content-center" style="z-index: 1"
              [ngClass]="[playersInViewOrder[8].status == 'requestExit' ? 'visibility-hidden' : '', playersInViewOrder[8].status !== 'playing' ? 'opacity-05' : '']">
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[8].user?._id ? 'active-player-border' : ''"
                class="profilePicture8 d-flex align-items-center justify-content-center"
                [style.border]="getPlayerBorderColor(playersInViewOrder[8])" (click)="selectPlayerColor(8)">
                <div *ngIf="playersInViewOrder[8]?.playerData && !playersInViewOrder[8]?.playerData?.profilePicture"
                  class="text-uppercase">
                  {{playersInViewOrder[8].playerData!.nickname[0]}}
                </div>
                <img *ngIf="playersInViewOrder[8]?.playerData && playersInViewOrder[8]?.playerData?.profilePicture"
                  class="img-fluid img8" src="{{playersInViewOrder[8].playerData!.profilePicture}}">
              </div>
              <div *ngIf="playersInViewOrder[8].status == 'absence'"
                class="sittingOut8 d-flex w-100 justify-content-center">Sitting Out</div>
              <div [ngClass]="tableGame?.currentTurn == playersInViewOrder[8].user?._id ? 'active-player-border' : ''"
                class="info8">
                <div *ngIf="!showActionPlayer8" class="nickname8 d-flex w-100 justify-content-center text-nowrap">
                  {{playersInViewOrder[8].playerData?.nickname}}</div>
                <div *ngIf="animationPlayerIndex === 8 && showActionPlayer8"
                  class="nickname8 d-flex w-100 justify-content-center">{{animationResponse?.action}}</div>
                <div
                  *ngIf="showValuesInBigBlindsFormat && (playersInViewOrder[8].coinAmount > 0 || playersInViewOrder[8].coinAmount == 0 && playersInViewOrder[8].status !== 'playing')"
                  class="balance8 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[8].coinAmount / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</div>
                <div
                  *ngIf="!showValuesInBigBlindsFormat && (playersInViewOrder[8].coinAmount > 0 || playersInViewOrder[8].coinAmount == 0 && playersInViewOrder[8].status !== 'playing')"
                  class="balance8 d-flex w-100 justify-content-center">
                  {{playersInViewOrder[8].coinAmount}}</div>
                <div *ngIf="playersInViewOrder[8].coinAmount == 0 && playersInViewOrder[8].status == 'playing'"
                  class="balance8 d-flex w-100 justify-content-center">All In</div>
              </div>
              <div
                [ngClass]="tableGame?.currentTurn == playersInViewOrder[8].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions ? '' : 'visibility-hidden'"
                class="progress-bar8">
                <div class="progressWidth" [ngStyle]="{ 'width': progressWidth + '%' }"
                  [ngClass]="{'bg-red': progressWidth <= 25 }">
                  <div
                    *ngIf="tableGame?.currentTurn == playersInViewOrder[8].user?._id && tableGame?.round !== 'SHOWDOWN' && tableGame?.showActions && timeCounterInSeconds !== 0 && this.progressWidth > -1"
                    class="time-counter mt-1">
                    TB: {{ getTimeBankMapValueByPlayer(playersInViewOrder[8].user?._id!) }}
                  </div>
                </div>
              </div>
              <div
                *ngIf="!arrayShowdownIndex[8] && playersInViewOrder[8]?.playerHand && playersInViewOrder[8]?.playerHand?.cards!.length && !isFold(8)"
                class="div-cards-face-down8" (click)="selectPlayerColor(8)">
                <img class="card-face-down8-1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(8)">
                <img class="card-face-down8-2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'"
                  (click)="selectPlayerColor(8)">
              </div>
              <div *ngIf="arrayShowdownIndex[8] && playersInViewOrder[8]?.playerHand?.cards?.length && !isFold(8)"
                class="div-showdown-player8" (click)="selectPlayerColor(8)">
                <img class="showdown-card-player8-1"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[8].playerHand!.cards[0] + '.png'"
                  (click)="selectPlayerColor(8)">
                <img class="showdown-card-player8-2"
                  [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + playersInViewOrder[8].playerHand!.cards[1] + '.png'"
                  (click)="selectPlayerColor(8)">
              </div>
            </div>
            <img *ngIf="tableGame?.dealerTurn == playersInViewOrder[8]?.user?._id" class="dealer8"
              src="../../../assets/icons/poker-game/coin-dealer.svg">
            <div *ngIf="betByPlayer(8) > 0 && !tableGame?.inResolveGame" class="bet8">
              <div class="d-flex flex-row-reverse">
                <ng-container *ngFor="let chip of getChipColor(betByPlayer(8)) | keyvalue">
                  <div class="chip-stack">
                    <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img">
                  </div>
                </ng-container>
                <span *ngIf="showValuesInBigBlindsFormat" class="me-2"> {{betByPlayer(8) / tableGame?.bigBlindValue! |
                  mask: 'separator.1'}}
                  BB</span>
                <span *ngIf="!showValuesInBigBlindsFormat" class="me-2">$ {{betByPlayer(8)}}</span>
              </div>
            </div>
          </div>
          <img *ngIf="lang === 'en'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(8)"
            [ngClass]="playersInViewOrder[8] && playersInViewOrder[8].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player8 cursor-pointer" src="../../../assets/icons/take-seat.svg" alt="">
          <img *ngIf="lang === 'pt'" data-bs-toggle="modal" data-bs-target="#modalBuyIn" (click)="openBuyIn(8)"
            [ngClass]="playersInViewOrder[8] && playersInViewOrder[8].status == 'empty' && !playerInTableGame() && !tableGame?.startGameLoading ? '' : 'visibility-hidden'"
            class="add-player8 cursor-pointer" src="../../../assets/icons/sentar.svg" alt="">
        </div> -->

        <div class="div-pot d-flex justify-content-center align-items-center">
          <div *ngIf="tableGame?.tableStatus! == 'PLAYING' && showValuesInBigBlindsFormat" class="pot"><span>Pot:
              {{tableGame?.pot! / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</span></div>
          <div *ngIf="tableGame?.tableStatus! == 'PLAYING' && !showValuesInBigBlindsFormat" class="pot"><span>Pot: $
              {{tableGame?.pot}}</span></div>
        </div>

        <div *ngIf="visualPots.length > 0 && getPotValue(0) > 0" class="div-pot0 d-flex justify-content-center">
          <div *ngIf="showValuesInBigBlindsFormat" class="pot0 d-flex">
            <ng-container *ngFor="let chip of getChipColor(getPotValue(0)) | keyvalue">
              <div class="chip-stack">
                <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img me-2">
              </div>
            </ng-container>
            <span class="shadow-pot0">{{getPotValue(0) / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</span>
          </div>
          <div *ngIf="!showValuesInBigBlindsFormat" class="pot0 d-flex">
            <ng-container *ngFor="let chip of getChipColor(getPotValue(0)) | keyvalue">
              <div class="chip-stack">
                <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img me-2">
              </div>
            </ng-container>
            <div class="shadow-pot0">$ {{getPotValue(0)}}</div>
          </div>
        </div>

        <div *ngIf="visualPots.length > 1 && getPotValue(1) > 0" class="div-pot1 d-flex justify-content-center">
          <div *ngIf="showValuesInBigBlindsFormat" class="pot1 d-flex">
            <ng-container *ngFor="let chip of getChipColor(getPotValue(1)) | keyvalue">
              <div class="chip-stack">
                <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img me-2">
              </div>
            </ng-container>
            <span class="shadow-pot1">{{getPotValue(1) / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</span>
          </div>
          <div *ngIf="!showValuesInBigBlindsFormat" class="pot1 d-flex">
            <ng-container *ngFor="let chip of getChipColor(getPotValue(1)) | keyvalue">
              <div class="chip-stack">
                <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img me-2">
              </div>
            </ng-container>
            <div class="shadow-pot1">$ {{getPotValue(1)}}</div>
          </div>
        </div>

        <div *ngIf="visualPots.length > 2 && getPotValue(2) > 0" class="div-pot2 d-flex justify-content-center">
          <div *ngIf="showValuesInBigBlindsFormat" class="pot2 d-flex">
            <ng-container *ngFor="let chip of getChipColor(getPotValue(2)) | keyvalue">
              <div class="chip-stack">
                <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img me-2">
              </div>
            </ng-container>
            <span class="shadow-pot2">{{getPotValue(2) / tableGame?.bigBlindValue! | mask: 'separator.1'}} BB</span>
          </div>
          <div *ngIf="!showValuesInBigBlindsFormat" class="pot2 d-flex">
            <ng-container *ngFor="let chip of getChipColor(getPotValue(2)) | keyvalue">
              <div class="chip-stack">
                <img *ngFor="let i of [].constructor(chip.value)" [src]="chip.key" class="bet-img me-2">
              </div>
            </ng-container>
            <div class="shadow-pot2">$ {{getPotValue(2)}}</div>
          </div>
        </div>

        <div>
          <img *ngIf="tableGame?.flopCards?.length && tableGame?.round !== 'PRE_FLOP'" class="flop-cards flop-card0"
            [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + tableGame!.flopCards[0] + '.png'">

          <img *ngIf="tableGame?.flopCards?.length && tableGame?.round !== 'PRE_FLOP'" class="flop-cards flop-card1"
            [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + tableGame!.flopCards[1] + '.png'">
          <img *ngIf="tableGame?.flopCards?.length && tableGame?.round !== 'PRE_FLOP'" class="flop-cards flop-card2"
            [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + tableGame!.flopCards[2] + '.png'">
          <img
            *ngIf="tableGame?.flopCards?.length && (tableGame?.round == 'TURN' || tableGame?.round == 'RIVER'  || tableGame?.round == 'SHOWDOWN')"
            class="flop-cards flop-card3"
            [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + tableGame!.flopCards[3] + '.png'">
          <img *ngIf="tableGame?.flopCards?.length && (tableGame?.round == 'RIVER'  || tableGame?.round == 'SHOWDOWN')"
            class="flop-cards flop-card4"
            [src]="'../../../assets/game/' + (selectedDeck === 'normal' ? 'new_cards' : 'colored_cards') + '/' + tableGame!.flopCards[4] + '.png'">
        </div>

        <!-- Animação das cartas indo para os jogadores no inicio da rodada -->
        <!-- <div id="animationDivSetCardsToPlayer0">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer1">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer2">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer3">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer4">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer5">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer6">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer7">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToPlayer8">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div> -->
        <div *ngFor="let player of players; let i = index" [attr.id]="'animationDivSetCardsToPlayer' + i">
          <img class="animationSetCardsToPlayer-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToPlayer-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>


        <!-- Animação das cartas quando o jogador da FOLD -->
        <!-- <div id="animationDivSetCardsToTable0">
          <img class="animationSetCardsToTable0-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable0-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable1">
          <img class="animationSetCardsToTable1-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable1-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable2">
          <img class="animationSetCardsToTable2-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable2-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable3">
          <img class="animationSetCardsToTable3-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable3-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable4">
          <img class="animationSetCardsToTable4-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable4-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable5">
          <img class="animationSetCardsToTable5-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable5-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable6">
          <img class="animationSetCardsToTable6-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable6-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable7">
          <img class="animationSetCardsToTable7-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable7-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>
        <div id="animationDivSetCardsToTable8">
          <img class="animationSetCardsToTable8-card1" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img class="animationSetCardsToTable8-card2" [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div> -->
        <div *ngFor="let player of players; let i = index" [attr.id]="'animationDivSetCardsToTable' + i">
          <img [attr.class]="'animationSetCardsToTable' + i + '-card1'"
            [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
          <img [attr.class]="'animationSetCardsToTable' + i + '-card2'"
            [src]="'../../../assets/game-v2/mesa/Card_Back_A.png'">
        </div>

        <!-- Animação (BET CALL RAISE E ALLIN) das fichas saindo do jogador para a mesa -->
        <!-- <img id="animationChipsFromHandToTableIndex0" class="bet-img cursor-pointer"
          (click)="changeShowValuesInBigBlindsFormat()" [src]="getChipColorAnimation(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex1" class="bet-img"
          [src]="getChipColorAnimation(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex2" class="bet-img"
          [src]="getChipColorAnimation(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex3" class="bet-img"
          [src]="getChipColorAnimation(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex4" class="bet-img"
          [src]="getChipColorAnimation(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex5" class="bet-img"
          [src]="getChipColorAnimation(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex6" class="bet-img"
          [src]="getChipColorAnimation(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex7" class="bet-img"
          [src]="getChipColorAnimation(animationResponse?.bet!)">
        <img id="animationChipsFromHandToTableIndex8" class="bet-img"
          [src]="getChipColorAnimation(animationResponse?.bet!)"> -->

        <div *ngFor="let player of players; let i = index">
          <img [id]="'animationChipsFromHandToTableIndex' + i" class="bet-img" [ngClass]="{'cursor-pointer': i === 0}"
            (click)="i === 0 ?  changeShowValuesInBigBlindsFormat() : null"
            [src]="getChipColorAnimation(animationResponse?.bet!)">
        </div>


        <!-- Animação das fichas saindo da frente do jogador para a pot visual 0 -->
        <!-- <img id="animationChipsGoingToVisualPot1Index0" class="bet-img" [src]="getChipColorAnimation(betByPlayer(0))">
        <img id="animationChipsGoingToVisualPot1Index1" class="bet-img" [src]="getChipColorAnimation(betByPlayer(1))">
        <img id="animationChipsGoingToVisualPot1Index2" class="bet-img" [src]="getChipColorAnimation(betByPlayer(2))">
        <img id="animationChipsGoingToVisualPot1Index3" class="bet-img" [src]="getChipColorAnimation(betByPlayer(3))">
        <img id="animationChipsGoingToVisualPot1Index4" class="bet-img" [src]="getChipColorAnimation(betByPlayer(4))">
        <img id="animationChipsGoingToVisualPot1Index5" class="bet-img" [src]="getChipColorAnimation(betByPlayer(5))">
        <img id="animationChipsGoingToVisualPot1Index6" class="bet-img" [src]="getChipColorAnimation(betByPlayer(6))">
        <img id="animationChipsGoingToVisualPot1Index7" class="bet-img" [src]="getChipColorAnimation(betByPlayer(7))">
        <img id="animationChipsGoingToVisualPot1Index8" class="bet-img" [src]="getChipColorAnimation(betByPlayer(8))"> -->
        <div *ngFor="let player of players; let i = index">
          <img [id]="'animationChipsGoingToVisualPot1Index' + i" class="bet-img"
            [src]="getChipColorAnimation(betByPlayer(i))">
        </div>

        <!-- Animação das fichas saindo da frente do jogador para a pot visual 1 -->
        <!-- <img id="animationChipsGoingToVisualPot0Index0" class="bet-img" [src]="getChipColorAnimation(betByPlayer(0))">
        <img id="animationChipsGoingToVisualPot0Index1" class="bet-img" [src]="getChipColorAnimation(betByPlayer(1))">
        <img id="animationChipsGoingToVisualPot0Index2" class="bet-img" [src]="getChipColorAnimation(betByPlayer(2))">
        <img id="animationChipsGoingToVisualPot0Index3" class="bet-img" [src]="getChipColorAnimation(betByPlayer(3))">
        <img id="animationChipsGoingToVisualPot0Index4" class="bet-img" [src]="getChipColorAnimation(betByPlayer(4))">
        <img id="animationChipsGoingToVisualPot0Index5" class="bet-img" [src]="getChipColorAnimation(betByPlayer(5))">
        <img id="animationChipsGoingToVisualPot0Index6" class="bet-img" [src]="getChipColorAnimation(betByPlayer(6))">
        <img id="animationChipsGoingToVisualPot0Index7" class="bet-img" [src]="getChipColorAnimation(betByPlayer(7))">
        <img id="animationChipsGoingToVisualPot0Index8" class="bet-img" [src]="getChipColorAnimation(betByPlayer(8))"> -->
        <div *ngFor="let player of players; let i = index">
          <img [id]="'animationChipsGoingToVisualPot0Index' + i" class="bet-img"
            [src]="getChipColorAnimation(betByPlayer(i))">
        </div>

        <!-- Animação das fichas saindo da frente do jogador para a pot visual 2 -->
        <!-- <img id="animationChipsGoingToVisualPot2Index0" class="bet-img" [src]="getChipColorAnimation(betByPlayer(0))">
        <img id="animationChipsGoingToVisualPot2Index1" class="bet-img" [src]="getChipColorAnimation(betByPlayer(1))">
        <img id="animationChipsGoingToVisualPot2Index2" class="bet-img" [src]="getChipColorAnimation(betByPlayer(2))">
        <img id="animationChipsGoingToVisualPot2Index3" class="bet-img" [src]="getChipColorAnimation(betByPlayer(3))">
        <img id="animationChipsGoingToVisualPot2Index4" class="bet-img" [src]="getChipColorAnimation(betByPlayer(4))">
        <img id="animationChipsGoingToVisualPot2Index5" class="bet-img" [src]="getChipColorAnimation(betByPlayer(5))">
        <img id="animationChipsGoingToVisualPot2Index6" class="bet-img" [src]="getChipColorAnimation(betByPlayer(6))">
        <img id="animationChipsGoingToVisualPot2Index7" class="bet-img" [src]="getChipColorAnimation(betByPlayer(7))">
        <img id="animationChipsGoingToVisualPot2Index8" class="bet-img" [src]="getChipColorAnimation(betByPlayer(8))"> -->
        <div *ngFor="let player of players; let i = index">
          <img [id]="'animationChipsGoingToVisualPot2Index' + i" class="bet-img"
            [src]="getChipColorAnimation(betByPlayer(i))">
        </div>

        <!-- Animação das fichas saindo do pot visual 0 para o vencedor -->
        <!-- <img id="animationChipsFromPot0ToPlayerIndex0" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex1" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex2" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex3" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex4" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex5" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex6" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex7" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot0ToPlayerIndex8" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)"> -->
        <div *ngFor="let player of players; let i = index">
          <img [id]="'animationChipsFromPot0ToPlayerIndex' + i" class="bet-img"
            [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        </div>

        <!-- Animação das fichas saindo do pot visual 1 para o vencedor -->
        <!-- <img id="animationChipsFromPot1ToPlayerIndex0" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex1" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex2" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex3" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex4" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex5" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex6" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex7" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot1ToPlayerIndex8" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)"> -->
        <div *ngFor="let player of players; let i = index">
          <img [id]="'animationChipsFromPot1ToPlayerIndex' + i" class="bet-img"
            [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        </div>

        <!-- Animação das fichas saindo do pot visual 2 para o vencedor -->
        <!-- <img id="animationChipsFromPot2ToPlayerIndex0" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex1" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex2" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex3" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex4" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex5" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex6" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex7" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        <img id="animationChipsFromPot2ToPlayerIndex8" class="bet-img"
          [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)"> -->
        <div *ngFor="let player of players; let i = index">
          <img [id]="'animationChipsFromPot2ToPlayerIndex' + i" class="bet-img"
            [src]="getChipColorAnimation(animationGiveChipsToWinners?.bet!)">
        </div>

      </div>


    </div>

    <!-- Ações -->
    <div class="row mt-5">
      <div class="col-sm-6 div-left-margin-top">
        <!-- Opções para sair da mesa e chat -->
        <div>
          <div class="bg-select-container">
            <select class="bg-select form-select mb-1" (change)="setLeaveRequestStatus($event)">
              <option selected disabled>{{ 'GAME_SELECT.PLACEHOLDER' | translate }}</option>
              <option value="sitInAllCashTable">{{ 'GAME_SELECT.SIT_OUT_OP1' | translate }}</option>
              <option value="sitOutNextBBHandAllCashTable">{{ 'GAME_SELECT.SIT_OUT_OP2' | translate }}</option>
              <option value="sitOutNextHandAllCashTable">{{ 'GAME_SELECT.SIT_OUT_OP3' | translate }}</option>
              <option value="leaveAllTableNextBB">{{ 'GAME_SELECT.SIT_OUT_OP4' | translate }}</option>
              <option value="leaveTableNextBB">{{ 'GAME_SELECT.SIT_OUT_OP5' | translate }}</option>
            </select>
          </div>
          <!-- <div class="bg-select-container" *ngIf="isSelectVisible && playerList.length !== 0">
            <select class="bg-select form-select mb-1" (change)="onSelectChange($event)">
              <option selected disabled>Selecione</option>
              <option value="sitInAllCashTable" *ngFor="let player of playerList" value="{{player.user!._id}}">{{player.user!.name}}</option>

            </select>
          </div> -->

          <div class="chat">
            <div class="row">
              <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="options" id="option1" autocomplete="off" checked
                  (click)="SetControlPanel('chat')">
                <label class="btn btn-secondary" for="option1">Chat</label>

                <input type="radio" class="btn-check" name="options" id="option2" autocomplete="off"
                  (click)="SetControlPanel('hand')">
                <label class="btn btn-secondary" for="option2">{{ 'GAME_SELECT.HANDS' | translate }}</label>

                <input type="radio" class="btn-check" name="options" id="option3" autocomplete="off"
                  (click)="SetControlPanel('nota')">
                <label class="btn btn-secondary" for="option3">{{ 'GAME_SELECT.NOTES' | translate }}
                </label>

                <input type="radio" class="btn-check" name="options" id="option4" autocomplete="off"
                  (click)="toggleCollapse()">
                <label class="btn btn-secondary" for="option4"><img
                    src="../../../assets/icons/arrow-down-white.svg"></label>
              </div>
            </div>
            <div *ngIf="!isCollapsed">
              <div *ngIf="controlPanel == 'chat'" class="row d-flex align-items-center justify-content-center">
                <app-chat></app-chat>
              </div>
              <div *ngIf="controlPanel == 'hand' && isViewer === false"
                class="row d-flex align-items-center justify-content-center">
                <app-hands [tableRound]="tableRound"></app-hands>
              </div>
              <div *ngIf="controlPanel == 'nota'" class="row d-flex align-items-center justify-content-center">

                <app-notes [playerToSetColor]="playerToSetColor"></app-notes>

              </div>
            </div>

          </div>
        </div>

      </div>



      <div [ngClass]="isCollapsed ? 'div-margin-top-176' : 'div-margin-top-25'" class="col-sm-6">
        <div class="container container-actions d-flex justify-content-end div-botoes-center-1100px">
          <div class="div-actions">
            <!-- Botões de jogar uma mesa similar e entra fila de espera -->
            <div *ngIf="this.isUserPlaying === false" class="row">
              <div *ngIf="alreadyInQueue === true && this.fullRoom === true" class="col-md-6">
                <button type="button" class="btn-continue subtitle-700-md mt-2" (click)="getByAproxBlindValue()">
                  {{ 'GAME.BTN_1' | translate }}</button>
              </div>

              <div *ngIf="alreadyInQueue === false && this.fullRoom === true" class="col-md-6">
                <button type="button" class="btn-lista-espera subtitle-700-md mt-2" data-bs-toggle="modal"
                  data-bs-target="#modalBuyIn" (click)="openBuyIn(999)">{{ 'GAME.BTN_2' | translate }}</button>
              </div>

              <div class="col-md-6">

                <button type="button" class="btn-lista-espera subtitle-700-md mt-2"
                  *ngIf="alreadyInQueue === true && this.fullRoom === true" (click)="removeFromQueue()">{{ 'GAME.BTN_3'
                  | translate }} {{playerWaitingListPosition + 1}}º</button>
              </div>
            </div>
            <div class="d-flex justify-content-end">

              <div
                *ngIf="tableGame?.showActions && (getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!) > 0 || progressWidth > 0) && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && isMainPlayer()"
                class=" d-flex flex-fill align-items-end">
                <div
                  class="btn-actions-name-timebank d-flex flex-fill justify-content-center align-items-center  cursor-pointer">
                  Time Bank
                  <span class="bb-text"> {{getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!)}}</span>
                </div>
              </div>


              <form [formGroup]="form" class="size-test">
                <div class="flex-grow-1">
                  <span
                    *ngIf="tableGame?.flopCards?.length && playersInViewOrder[0]?.playerHand && playersInViewOrder[0]?.playerHand?.cards!.length && isMainPlayer()"
                    style="color:white">{{playerTip | translate}}</span>
                  <div
                    *ngIf="tableGame?.showActions && (getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!) > 0 || progressWidth > 0) && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && isMainPlayer()">
                    <div class="d-flex justify-content flex-wrap">
                      <button type="button" *ngIf="tableGame?.round == 'PRE_FLOP' && hasRaised === false"
                        class="btn-shortcuts-V2" (click)="clickShortcuts1()">2.5 BB</button>
                      <button type="button" *ngIf="tableGame?.round == 'PRE_FLOP' && hasRaised === true"
                        class="btn-shortcuts-V2" (click)="clickShortcuts1()">2.5 X</button>
                      <button type="button" *ngIf="tableGame?.round !== 'PRE_FLOP'" class="btn-shortcuts-V2"
                        (click)="clickShortcuts1()">33%</button>
                      <button type="button" *ngIf="tableGame?.round == 'PRE_FLOP' &&  hasRaised === false "
                        class="btn-shortcuts-V2" (click)="clickShortcuts2()">&nbsp;3 BB&nbsp;</button>
                      <button type="button" *ngIf="tableGame?.round == 'PRE_FLOP' && hasRaised === true "
                        class="btn-shortcuts-V2" (click)="clickShortcuts2()">&nbsp;3 X&nbsp;</button>
                      <button type="button" *ngIf="tableGame?.round !== 'PRE_FLOP'" class="btn-shortcuts-V2"
                        (click)="clickShortcuts2()">50%</button>
                      <button type="button" *ngIf="tableGame?.round == 'PRE_FLOP'  &&  hasRaised === false "
                        class="btn-shortcuts-V2" (click)="clickShortcuts3()">3.5 BB</button>
                      <button type="button" *ngIf="tableGame?.round == 'PRE_FLOP'  &&  hasRaised === true"
                        class="btn-shortcuts-V2" (click)="clickShortcuts3()">3.5 x</button>
                      <button type="button" *ngIf="tableGame?.round !== 'PRE_FLOP'" class="btn-shortcuts-V2"
                        (click)="clickShortcuts3()">75%</button>
                      <button type="button" class="btn-shortcuts-V2" (click)="clickShortcuts4()">Max</button>
                    </div>

                    <div>
                      <div class=" mt-1 d-flex align-items-center input-scroll-V2 ">
                        <div class="d-flex align-items-center scroll-value">
                          <span *ngIf="!showValuesInBigBlindsFormat" class="input-group-text ms-1">$</span>
                          <input *ngIf="!showValuesInBigBlindsFormat" type="number" formControlName="valueRange"
                            class="form-control input-custom-value-range" (keyup)="listenedRange($event, 'input')">
                          <input *ngIf="showValuesInBigBlindsFormat" type="number" formControlName="valueRangeBB"
                            class="form-control input-custom-value-range text-end ms-1"
                            (keyup)="listenedRange($event, 'inputBB')">
                          <span *ngIf="showValuesInBigBlindsFormat" class="input-group-text"> BB</span>
                        </div>
                        <input id="teste" type="range" formControlName="bet" class="form-range ms-2" style="width: 90%"
                          (change)="listenedRange($event, 'range')" [min]="minRaiseValue" [max]="getMaxRange()">
                      </div>
                    </div>
                  </div>

                  <div class="row ps-3 mt-1">
                    <div class="col-12 d-flex justify-content-end pe-0">

                      <div
                        *ngIf="tableGame?.showActions && (getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!) > 0 || progressWidth > 0) && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && !canCheck() &&  isMainPlayer()"
                        class="col-4 me-2  d-flex justify-content-center">
                        <div
                          class="btn-actions-name-fold d-flex justify-content-center align-items-center w-100 btn-actions cursor-pointer"
                          (click)="fold()">{{'GAME.FOLD' | translate}}</div>
                      </div>
                      <form [formGroup]="formCheckBoxs" *ngIf="isViewer === false && isMainPlayer()">
                        <div class="col-4 me-2 d-flex justify-content-center auto-fold"
                          *ngIf="tableGame?.showActions && tableGame?.currentTurn !== playersInViewOrder[0]?.user?._id ">
                          <div class="d-flex justify-content-center align-items-center w-100">
                            <input type="checkbox" id="automaticFold" (change)="autoFold()"
                              formControlName="automaticFold" />
                            <label for="automaticFold" class="ms-2 cursor-pointer">{{ 'GAME.AUTO_FOLD' | translate
                              }}</label>
                          </div>
                        </div>
                      </form>

                      <div
                        *ngIf="tableGame?.showActions && (getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!) > 0 || progressWidth > 0) && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canCheck() && isMainPlayer()"
                        class="col-4 me-2  d-flex justify-content-center">
                        <div
                          class="btn-actions-name-check d-flex justify-content-center align-items-center w-100 btn-actions cursor-pointer"
                          (click)="check()">Check
                        </div>
                      </div>
                      <div
                        *ngIf="tableGame?.showActions && (getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!) > 0 || progressWidth > 0) && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canCall() && !canCheck() &&  isMainPlayer()"
                        class="col-4 me-2 d-flex flex-column align-items-center">
                        <div
                          class="btn-actions-name-bet d-flex justify-content-center align-items-center w-100 btn-actions cursor-pointer"
                          (click)="call()">{{ 'GAME.CALL' | translate }}
                          <span class="bb-text" *ngIf="!showValuesInBigBlindsFormat">$ {{(getTopBetRound() -
                            getTopBetPlayer()).toFixed(2)}}</span>
                          <span class="bb-text" *ngIf="showValuesInBigBlindsFormat"> {{(getTopBetRound() -
                            getTopBetPlayer()) / tableGame?.bigBlindValue! | mask: 'separator.2'}} BB</span>
                        </div>

                      </div>
                      <div
                        *ngIf="tableGame?.showActions && (getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!) > 0 || progressWidth > 0) && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canRaise() && getTopBetRound() == 0 && isMainPlayer()"
                        (click)="raise()" class="col-4 me-2  d-flex flex-column align-items-center">
                        <div
                          class="btn-actions-name-allin d-flex justify-content-center align-items-center w-100 btn-actions cursor-pointer">
                          Bet
                          <span class="bb-text" *ngIf="!showValuesInBigBlindsFormat">$
                            {{(form.controls['bet'].value).toFixed(2)}}</span>
                          <span class="bb-text" *ngIf="showValuesInBigBlindsFormat">{{form.controls['bet'].value /
                            tableGame?.bigBlindValue! | mask: 'separator.2'}} BB</span>

                        </div>

                      </div>
                      <div
                        *ngIf="tableGame?.showActions && (getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!) > 0 || progressWidth > 0) && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canRaise() && getTopBetRound() > 0 &&  isMainPlayer()"
                        (click)="raise()" class="col-4 me-2 d-flex flex-column  align-items-center">
                        <div
                          class="btn-actions-name-allin d-flex justify-content-center align-items-center w-100 btn-actions cursor-pointer">
                          {{ 'GAME.RAISE' | translate }}
                          <span class="bb-text" *ngIf="!showValuesInBigBlindsFormat">$
                            {{(form.controls['bet'].value).toFixed(2)}}</span>
                          <span class="bb-text" *ngIf="showValuesInBigBlindsFormat">{{form.controls['bet'].value /
                            tableGame?.bigBlindValue! | mask: 'separator.2'}} BB</span>

                        </div>

                      </div>

                      <div
                        *ngIf="tableGame?.showActions && (getTimeBankMapValueByPlayer(playersInViewOrder[0]?.user?._id!) > 0 || progressWidth > 0) && tableGame?.currentTurn == playersInViewOrder[0]?.user?._id && canAllIn() && isMainPlayer()"
                        (click)="allIn()" class="col-4 me-2 d-flex flex-column  align-items-center">
                        <div
                          class="btn-actions-name-allin d-flex justify-content-center align-items-center w-100 btn-actions cursor-pointer">
                          All In
                          <span class="bb-text" *ngIf="!showValuesInBigBlindsFormat">$
                            {{form.controls['bet'].value}}</span>
                          <span class="bb-text" *ngIf="showValuesInBigBlindsFormat">{{form.controls['bet'].value /
                            tableGame?.bigBlindValue! | mask: 'separator.2'}} BB</span>

                        </div>

                      </div>

                      <div
                        *ngIf="playersInViewOrder[0]?.status == 'absence' && tableGame?.currentTurn !== playersInViewOrder[0]?.user?._id && playerInTableGame()"
                        class="d-flex mt-5 flex-fill w-100 justify-content-end">
                        <div (click)="presence()"
                          class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer"
                          style="max-width: 200px;">I'm Back</div>
                      </div>

                      <div *ngIf="playersInViewOrder[0]?.status == 'waitingForBigBlind' && playerInTableGame() "
                        class="d-flex mt-5 flex-fill w-100 justify-content-end">
                        <div (click)="payBigBlind()"
                          class="btn-actions-name d-flex justify-content-center align-items-center w-100 cursor-pointer"
                          style="max-width: 200px;">Pagar Big Blind</div>
                      </div>

                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- <div  *ngIf="isViewer === true">
              <div class="d-flex flex-fill justify-content-between">
                <button type="button" class="btn-continue button-bold w-100" (click)="fromViewerToPlayer()">{{"HOME.SITDOWN" | translate }}</button>

              </div>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal BuyIn-->
<div class="modal fade" id="modalBuyIn" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="modalBuyInLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-dark text-white p-3">
      <div class="modal-header">
        <h2 class="heading-700-sm ">Buy-in</h2>
        <button type="button" id="closeModal" class="btn-close btn-close-white" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-end" *ngIf="balanceValue?.coinUsdValue ">
          <span *ngIf="tableGame?.coin === 'USDT'" style="color: #27C69A;"> USDT Balance: {{balanceValue!.coinUsdValue |
            currencyFormat}}</span>
          <span *ngIf="tableGame?.coin === 'POKERFI'" style="color: #F3C41F;">Saldo PKF:
            {{balanceValue!.coinPokerFiValue | currencyFormat}}</span>
        </div>
        <form [formGroup]="formBuyIn">
          <div class="d-flex flex-fill flex-column">
            <div class="d-flex align-items-center mb-3">
              <label for="customRange2" class="form-label text-white mb-0">Amount Buy-in:</label>
              <input type="number" (keyup)="listenedBuyInRange($event, 'input')"
                class="form-control input-buyin-value-range" formControlName="buyIn" id="customRange2">
            </div>
            <div class="d-flex flex-fill">
              <div class="px-2 d-flex flex-column">
                <div>Min</div>
              </div>
              <input type="range" class="form-range" (change)="listenedBuyInRange($event, 'range')"
                (wheel)="onRangeScroll($event)" formControlName="valueRangeBuyIn" [min]="tableGame?.buyInMinimum"
                [max]="tableGame?.buyInMaximum" id="buyInRange">
              <div class="px-2 d-flex flex-column">
                <div>Max</div>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <span [ngStyle]="{'color': tableGame?.coin === 'USDT' ? '#27C69A' : '#F3C41F'}"><span
                  *ngIf="tableGame?.coin === 'USDT'" style="color: #27C69A;">USDT $ </span><span
                  *ngIf="tableGame?.coin === 'POKERFI'" style="color: #F3C41F;">PKF
                </span>{{tableGame?.buyInMinimum!}}</span>
              <span [ngStyle]="{'color': tableGame?.coin === 'USDT' ? '#27C69A' : '#F3C41F'}"><span
                  *ngIf="tableGame?.coin === 'USDT'" style="color: #27C69A;">USDT $ </span><span
                  *ngIf="tableGame?.coin === 'POKERFI'" style="color: #F3C41F;">PKF </span>
                {{tableGame?.buyInMaximum!}}</span>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-fill justify-content-between">
          <button type="button" id="cancelBuyIn" class="btn-delete button-bold w-50" data-bs-dismiss="modal"
            aria-label="Close">Cancelar</button>
          <button type="button" class="btn-continue button-bold w-50" *ngIf="!joinQueue" (click)="sitAtTableGame()">{{
            'GAME.BUY' | translate }}</button>
          <button type="button" class="btn-continue button-bold w-50" *ngIf="joinQueue"
            (click)="requestToJoinQueue()">Entrar na fila</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="btn-open-modal-rebuy" data-bs-toggle="modal" data-bs-target="#modalBuyCoin" (click)="openModalBuyCoin()"
  class="d-none"></div>

<!-- Modal BuyCoin -->
<div class="modal fade" id="modalBuyCoin" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="modalBuyCoinLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-dark text-white p-3">
      <div class="modal-header">
        <h2 class="heading-700-sm ">Buy more chips</h2>
        <button type="button" type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="d-flex justify-content-end">
          Your balance&nbsp;<span *ngIf="tableGame?.coin === 'USDT'"> USDT: $ {{balanceValue?.coinUsdValue}}</span>
          <span *ngIf="tableGame?.coin === 'POKERFI'"> PKF {{balanceValue?.coinPokerFiValue}}</span>
        </div>
        <form [formGroup]="formBuyCoin">
          <div class="d-flex flex-fill flex-column">

            <div class="d-flex align-items-center mb-3">
              <label for="customRange2" class="form-label text-white mb-0">Amount:
              </label>
              <input type="number" class="form-control input-buyin-value-range" formControlName="buyCoin"
                id="customRange2" (keyup)="listenedRangeBuyCoin($event, 'input')">
            </div>
            <div class="d-flex flex-fill">
              <div class="px-2 d-flex flex-column">
                <div>Min</div>
              </div>
              <input type="range" class="form-range" formControlName="valueRangeBuyCoin" [min]="buyCoinMin"
                [max]="buyCoinMax" id="buyInRange" (change)="listenedRangeBuyCoin($event, 'range')">
              <div class="px-2 d-flex flex-column">
                <div>Max</div>
              </div>
            </div>

            <div class="d-flex justify-content-between">
              <span><span *ngIf="tableGame?.coin === 'USDT'">USDT $ </span><span
                  *ngIf="tableGame?.coin === 'POKERFI'">PKF
                </span>{{buyCoinMin}}</span>
              <span><span *ngIf="tableGame?.coin === 'USDT'">USDT $ </span><span
                  *ngIf="tableGame?.coin === 'POKERFI'">PKF
                </span>{{buyCoinMax}}</span>
            </div>


          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="d-flex flex-fill justify-content-between">
          <button type="button" id="cancelBuyCoin" class="btn-delete button-bold w-50" data-bs-dismiss="modal"
            aria-label="Close">Cancelar</button>
          <button type="button" class="btn-continue button-bold w-50" (click)="buyCoin()">{{ 'GAME.BUY' | translate
            }}</button>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Notes -->
<div *ngIf="rgbModalStatus && playerToSetColor !== null" class="custom-modal">
  <div class="custom-modal-dialog custom-modal-dialog-centered">
    <div class="custom-modal-content bg-dark text-white p-3">
      <div class="custom-modal-header">
        <h2 class="heading-500-lg">{{ 'GAME.TITLE_MODAL' | translate }}</h2>
        <button type="button" class="btn-close btn-close-white ms-3" (click)="rgbModalStatus = false"
          aria-label="Close"></button>
      </div>
      <div class="custom-modal-body">
        <div class="color-grid color-grid-div">
          <div *ngFor="let color of colorArray" class="color-box" [ngStyle]="{'background-color': color.hex}"
            (click)="selectColor(color.hex)">
            <!-- <span *ngIf="color.hex === 'transparent'" class="transparent-color-x"></span> -->
          </div>
        </div>
      </div>
      <div class="custom-modal-footer">
        <button type="button" class="btn-continue button-bold w-100" (click)="rgbModalStatus = false">{{ 'GAME.CLOSE' |
          translate }}</button>
      </div>
    </div>
  </div>
</div>
