import { Injectable } from "@angular/core";
import { BehaviorSubject, ReplaySubject } from "rxjs";
import { Socket } from "socket.io-client";
import { BaseSocketService } from "./base.socket.service";
import { TableGameGetResponseDto } from "src/app/dtos/socket/table-game-get-response.dto";
import { SocketDto } from "src/app/dtos/socket/socket.dto";
import { CommonGatewayEventsEnum } from "src/app/dtos/enum/common.gateway.events.enum";
import { ResponseDto } from "src/app/dtos/response.dto";
import { environment } from "src/environments/environment";
import { TableGameGetDetailsResponseDto } from "../../app/dtos/socket/table-game-get-details-response.dto";

@Injectable({
  providedIn: "root",
})
export class CommonGatewaySocketService extends BaseSocketService {

  private _socket: Socket | undefined;

  tableGameList = new BehaviorSubject<TableGameGetResponseDto[]>([]);
  tableGameDetails = new ReplaySubject<TableGameGetDetailsResponseDto>();

  constructor(
  ) {
    super(
      new SocketDto(
        environment.apis.gameBackoffice,
        CommonGatewayEventsEnum.PATH.toString(),
      ));
  }

  initializeMain(): void {
    this._socket = this.initializeCommonRoutine(this._socket!);
  }

  desconected() {
    this._socket!.on('disconnect', (reason: string) => {
      console.log('Socket desconectado, motivo:', reason);
        this.initializeMain();
    });
  }

  requestTableGameList() {
    this._socket!.emit(CommonGatewayEventsEnum.REQUEST_LIST_TABLE_GAMES);
    this._socket!.on(CommonGatewayEventsEnum.RESPONSE_LIST_TABLE_GAMES, (response: ResponseDto) => {
      if (response.success)
        this.tableGameList.next(response.data);
    });
  }

  requestTableUpdateView(tableGameId: string, inOut: string) {
    this._socket!.emit(CommonGatewayEventsEnum.REQUEST_UPDATE_TABLE_VIEWS, { tableGameId, inOut });
    this._socket!.on(CommonGatewayEventsEnum.RESPONSE_LIST_TABLE_GAMES_DETAILS, (response: ResponseDto) => {
      if (response.success)
        this.tableGameDetails.next(response.data);
    });
  }

  //pf -578
  updateLeaveGameOfPlayerByIndex(_id: string, player: string, status: string) {
    this._socket!.emit(CommonGatewayEventsEnum.UPDATE_PLAYER_LEAVE_REQUEST, { _id, player, status });
    this._socket!.on(CommonGatewayEventsEnum.RESPONSE_LIST_TABLE_GAMES_DETAILS, (response: ResponseDto) => {
      if (response.success)
        this.tableGameDetails.next(response.data);
    });
  }

  requestUpdateTableWaitingList(_id: string, player: string, action: string, buyIn: number) {
    this._socket!.emit(CommonGatewayEventsEnum.REQUEST_UPDATE_WAITING_LIST, { _id, player, action, buyIn });
    this._socket!.on(CommonGatewayEventsEnum.RESPONSE_LIST_TABLE_GAMES_DETAILS, (response: ResponseDto) => {
      if (response.success)
        this.tableGameDetails.next(response.data);
    });
  }
  removeFromWaitingList(_id: string, player: string, action: string, buyIn: number) {
    this._socket!.emit(CommonGatewayEventsEnum.REMOVE_FROM_WAITING_LIST, { _id, player, action, buyIn });
    this._socket!.on(CommonGatewayEventsEnum.RESPONSE_LIST_TABLE_GAMES_DETAILS, (response: ResponseDto) => {
      if (response.success)
        this.tableGameDetails.next(response.data);
    });
  }

  requestTableGameDetails(tableGameId: string) {
    this._socket!.emit(CommonGatewayEventsEnum.REQUEST_LIST_TABLE_GAMES_DETAILS, { tableGameId, });
    this._socket!.on(CommonGatewayEventsEnum.RESPONSE_LIST_TABLE_GAMES_DETAILS, (response: ResponseDto) => {
      if (response.success)
        this.tableGameDetails.next(response.data);
    });
  }

  updateViewer(tableGameId: string) {
    this._socket!.emit(CommonGatewayEventsEnum.REQUEST_LIST_TABLE_GAMES_DETAILS, { tableGameId });
    this._socket!.on(CommonGatewayEventsEnum.RESPONSE_LIST_TABLE_GAMES_DETAILS, (response: ResponseDto) => {
      if (response.success)
        this.tableGameDetails.next(response.data);
    });
  }

  shutDownAll(): void {
    if (!!this._socket && this._socket.connected) {
      this._socket.offAny()
      this._socket.removeAllListeners();
      this._socket.close();
      this._socket.disconnect();
    }
  }
}
